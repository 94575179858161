.product-container {
  text-align: center;
  margin: 20px;
}

.product-image {
  width: 300px;         /* Fixed width */
  height: 300px;        /* Fixed height */
  object-fit: cover;    
  border-radius: 8px;
  border: 2px solid #ccc;
}

.product-name {
  margin-top: 10px;
  font-size: 18px;
  color: black;
}

.image-navigation {
  margin-top: 10px;
}

.image-navigation button {
  margin: 0 5px;
  padding: 10px;
  border: none;
  border-radius: 4px;
  background-color: #007bff;
  color: white;
  cursor: pointer;
}

.image-navigation button:hover {
  background-color: #0056b3;
}

/* Mobile screen styles */
@media (max-width: 768px) {
  .product-image {
    width: 250px;       /* Increased width for mobile screens */
    height: 250px;      /* Increased height for mobile screens */
  }

  .product-name {
    font-size: 16px;   /* Adjusted font size for mobile screens */
  }

  .image-navigation button {
    padding: 8px;      /* Adjusted padding for mobile screens */
  }
}

/* Extra small screen styles */
@media (max-width: 480px) {
  .product-image {
    width: 200px;       /* Further increased width for smaller mobile screens */
    height: 200px;      /* Further increased height for smaller mobile screens */
  }

  .product-name {
    font-size: 14px;   /* Further adjusted font size for smaller mobile screens */
  }

  .image-navigation button {
    padding: 6px;      /* Further adjusted padding for smaller mobile screens */
  }
}

/* Heading style */
.product-list-title {
  text-align: center;
  margin: 20px 0;
  font-size: 24px;
  color: #333;
}
