/* Container styling */
.con {
  padding: 20px;
  text-align: center;
}

.gallery-options {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.gallery-option {
  width: 300px; /* Increased width */
  margin: 0 20px; /* Increased margin */
  text-align: center;
  text-decoration: none;
  color: black;
}

.option-image {
  width: 100%;
  height: 200px; /* Increased height */
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.option-image:hover {
  transform: scale(1.05);
}

h3 {
  margin-top: 10px;
  font-size: 18px; /* Increased font size */
}
