/* General styles */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, sans-serif;
  background-color: #f4f1ea; /* Light beige background */
}

body {
  display: flex;
  flex-direction: column;
}

/* Header styles */
header {
  background-color: #755248; /* Vintage brown color */
  color: #f4f1ea; /* Light beige color */
  padding: 20px;
  text-align: center;
}

header h1 {
  margin: 0;
}

h2 {
  margin: 10px 0;
  color: #755248;
}

footer {
  background-color: #755248; /* Vintage brown color */
  color: #f4f1ea; /* Light beige color */
  padding: 20px;
  text-align: center;
  margin-top: auto; /* Push the footer to the bottom */
}

.container {
  flex: 1; /* Take up the remaining space */
  padding: 20px;
  text-align: center;
}

.con {
  flex: 1; /* Take up the remaining space */
  padding: 20px;
  text-align: center;
}

.gallery img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 10px 0;
}

/* Slideshow styles */
.slideshow {
  width: 100%;
  max-width: 800px; /* Increased max width for larger screens */
  margin: 20px auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.slideshow-image {
  width: 100%; /* Ensure the image takes the full width */
  height: auto;
  max-height: 400px; /* Default size */
  border-radius: 10px;
  transition: opacity 1s ease-in-out;
  object-fit: cover; /* Ensure the image covers the container */
}

/* Notification bar styles */
.notification-bar {
  width: 25%; /* Adjust width as needed */
  height: 400px; /* Same height as the image */
  overflow: hidden;
  position: relative;
  border-left: 1px solid #ddd;
  margin-left: 20px;
  background-color: #f4f1ea; /* Light beige background */
  color: #755248; /* Vintage brown color */
  padding: 10px;
  box-sizing: border-box;
}
/* Popup styles */
.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Darker background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: #fff; /* White background */
  padding: 30px; /* Increased padding */
  border-radius: 15px; /* More rounded corners */
  text-align: center;
  position: relative;
  max-width: 80%; /* Adjusted max width */
  max-height: 80%; /* Adjusted max height */
  overflow: auto;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* Added shadow */
}

.popup-image {
  max-width: 60%; /* Adjusted maximum width */
  height: auto;
  margin-bottom: 20px;
}

.close-button {
  position: absolute;
  top: 15px; /* Adjusted position */
  right: 15px; /* Adjusted position */
  background: #333; /* Darker background */
  color: #fff; /* White color */
  border: none;
  font-size: 24px; /* Increased font size */
  cursor: pointer;
  border-radius: 50%; /* Rounded button */
  width: 40px; /* Fixed width */
  height: 40px; /* Fixed height */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Mobile styles */
@media (max-width: 600px) {
  .popup-content {
    padding: 10px;
  }

  .popup-image {
    max-width: 80%; /* Adjust the maximum width for mobile */
  }

  .close-button {
    font-size: 18px;
  }
}


.notification-text {
  display: block;
  width: 100%;
  height: 100%;
  animation: scroll-vertical 10s linear infinite;
}

@keyframes scroll-vertical {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(-100%);
  }
}

/* New styles for the info sections */
.info-sections {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin-top: 20px;
}

.info-section {
  flex: 1 1 45%; /* Two items per row */
  max-width: 45%;
  text-align: center;
  margin: 10px;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  background-color: #fff; /* White background */
}

.info-icon {
  width: 50px;
  height: 50px;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .info-section {
    flex: 1 1 100%; /* One item per row on small screens */
    max-width: 100%;
  }
}

/* Responsive design for different screen sizes */
@media (max-width: 1200px) {
  .slideshow-image {
    max-height: 350px;
  }
}

@media (max-width: 992px) {
  .slideshow-image {
    max-height: 300px;
  }
}

@media (max-width: 768px) {
  .slideshow-image {
    max-height: 250px;
  }
}

@media (max-width: 576px) {
  .slideshow-image {
    max-height: 200px;
  }
}

/* Additional styles for aligning the image and content */
.con {
  padding-top: 40px; /* Add padding to accommodate the notification bar */
}

.content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.slideshow {
  flex: 0 0 70%; /* Make slideshow take 70% of the space */
}

.notification-bar {
  flex: 0 0 30%; /* Make notification bar take 30% of the space */
}

/* Media query to adjust layout on small screens */
@media (max-width: 768px) {
  .content {
    flex-direction: column;
  }
  .notification-bar {
    width: 100%;
    height: auto;
    order: 2; /* Move the notification bar below the slideshow */
    border-left: none;
    border-top: 1px solid #ddd;
  }
  .slideshow {
    order: 1; /* Keep the slideshow above the notification bar */
  }
  .notification-text {
    animation: scroll-horizontal 10s linear infinite;
  }
}

@keyframes scroll-horizontal {
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(-100%);
  }
}

/* Styles for the notification photos to show along with the names */
.photo-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.person-photo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}
