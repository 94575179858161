/* Base styles */
.main-container {
  padding: 20px;
  text-align: center;
}

h1, h3 {
  padding: 10px;
  padding-bottom: 30px;
  text-align: center;
}

.info-vist {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.leader {
  text-align: center;
}

.leader-image {
  width: 100%;
  padding-bottom: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.leader-image:hover,
.leader-image:active {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.leader-text {
  margin-top: 10px;
  font-size: 16px; /* Increased font size */
  color: #333;
  transition: background-color 0.3s ease, color 0.3s ease; /* Added transition */
}

.leader-image:hover + .leader-text,
.leader-image:active + .leader-text {
  background-color: rgba(255, 255, 255, 0.7); /* Added background color */
  color: #000; /* Ensure text color is dark */
  padding: 5px; /* Added padding */
  border-radius: 5px; /* Added border radius */
}

/* Mobile styles */
@media (max-width: 768px) {
  .info-vist {
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
  }
}

@media (max-width: 480px) {
  .info-vist {
    grid-template-columns: 1fr;
    gap: 10px;
  }
}
