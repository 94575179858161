/* Base styles */
.aboutus-container {
  padding: 20px;
  text-align: center;
}

h1, h2 {
  padding: 10px;
  padding-bottom: 20px;
}

.map-container {
  margin: 20px auto;
  max-width: 800px;
}

.contact-form {
  margin-top: 20px;
  text-align: left;
  max-width: 600px;
  margin: 0 auto;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

button {
  padding: 10px 15px;
  border: none;
  background-color: #007BFF;
  color: white;
  border-radius: 4px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Image size adjustment */
.map-container img {
  width: 200px; /* Fixed width */
  height: auto; /* Maintain aspect ratio */
  margin: 0 auto; /* Center the image */
}

/* Mobile styles */
@media (max-width: 768px) {
  .map-container iframe {
    width: 100%;
    height: 300px;
  }

  .contact-form {
    padding: 0 10px;
    max-width: 100%;
  }

  .map-container img {
    width: 150px; /* Smaller fixed width for mobile */
  }
}

@media (max-width: 480px) {
  .aboutus-container {
    padding: 10px;
  }

  h1, h2 {
    font-size: 1.5em;
    padding-bottom: 15px;
  }

  .contact-form {
    padding: 0 5px;
  }

  .form-group label,
  .form-group input,
  .form-group textarea {
    font-size: 0.9em;
  }

  button {
    padding: 8px 12px;
    font-size: 0.9em;
  }

  .map-container img {
    width: 100px; /* Even smaller fixed width for very small screens */
  }
}



