/* Navigation bar styles */
nav {
    background-color: #755248; /* Vintage brown color */
    padding: 10px 0;
    overflow-x: auto; /* Enable horizontal scrolling for smaller screens */
    display: flex;
    justify-content: center; /* Center the content horizontally */
    align-items: center; /* Center items vertically */
}

nav ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center; /* Center items vertically */
}

nav ul li {
    margin: 0 10px;
}

nav ul li a {
    text-decoration: none;
    color: #f4f1ea;
    padding: 10px 15px;
    border-radius: 5px;
    transition: background-color 0.3s ease;
    white-space: nowrap; /* Prevent text from wrapping */
}

nav ul li a:hover {
    background-color: #967d6b; /* Slightly darker vintage brown */
}

/* Logo container specific styles */
.logo-container {
    position: absolute; /* Position logo separately */
    left: 10px; /* Space from the left edge */
}

.logo {
    height: 50px; /* Adjust height of logo as needed */
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
    nav ul {
        flex-direction: row; /* Stack items vertically on small screens */
        align-items: center; /* Center items horizontally */
    }

    nav ul li {
        margin: 5px 0; /* Space out items vertically */
    }

    .logo {
        height: 40px; /* Adjust logo height for smaller screens */
    }
}

@media (max-width: 480px) {
    nav ul li a {
        font-size: 14px;
        padding: 10px 5px; /* Further reduce padding for very small screens */
    }

    .logo {
        height: 30px; /* Adjust logo height for very small screens */
    }
}
