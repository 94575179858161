.photos-container {
  padding: 20px;
  text-align: center;
  color: black; /* Set text color to black */
  background-color: #f9f9f9; /* Light background color for better contrast */
  border-radius: 8px; /* Rounded corners for the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}

.category-list {
  margin-bottom: 20px;
  display: flex; /* Flexbox for better alignment */
  justify-content: center; /* Center the buttons */
  flex-wrap: wrap; /* Allow buttons to wrap to new lines if necessary */
  gap: 10px; /* Add gap between buttons */
}

.category-list button {
  background-color: #f0f0f0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  color: black; /* Set text color to black */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition for hover effect */
}

.category-list button:hover {
  background-color: #e0e0e0; /* Light grey background on hover */
}

.category-list button.active {
  background-color: #007bff;
  color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* Slight shadow for active button */
}

.photo-grid {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

.photo-grid img {
  width: 100%;
  max-width: 300px; /* Increase maximum width for images */
  height: auto;
  border-radius: 8px;
  border: 2px solid #ccc;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition for hover effects */
}

.photo-grid img:hover {
  transform: scale(1.05); /* Slight zoom effect on hover */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow on hover */
}

/* Media queries for responsive design */
@media (max-width: 768px) {
  .photo-grid img {
    max-width: 250px; /* Adjust maximum width for tablets */
  }
  .category-list button {
    font-size: 14px; /* Reduce font size for tablets */
    padding: 8px 16px; /* Adjust padding for tablets */
  }
}

@media (max-width: 480px) {
  .photo-grid img {
    max-width: 200px; /* Adjust maximum width for mobile devices */
  }
  .category-list button {
    font-size: 12px; /* Further reduce font size for mobile devices */
    padding: 6px 12px; /* Adjust padding for mobile devices */
  }
}
