.container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  padding: 20px;
  margin: 20px auto;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
  max-width: 1200px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center items horizontally */
  margin-bottom: 20px;
}

.header h1 {
  margin: 0 0 10px 0;
  font-size: 32px; /* Increased font size for h1 */
  text-align: center;
}

.photos {
  margin-bottom: 20px;
  display: flex;
  justify-content: center; /* Center the images */
}

.photos img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  border: 2px solid #ccc;
}

.info {
  width: 100%;
}

.info p {
  font-size: 18px; /* Increased font size for paragraph text */
  line-height: 1.5;
  color: #333;
  margin: 0;
  padding: 0;
  text-align: justify;
}

@media (max-width: 768px) {
  .container {
    align-items: center;
  }

  .header {
    align-items: center;
  }

  .info {
    text-align: center;
  }
}
