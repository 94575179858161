.videos-page {
  padding: 20px;
}

.video-gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.video-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 640px; /* Increased width */
}

video {
  width: 100%;
  height: auto; /* Maintain aspect ratio */
}
